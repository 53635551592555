import { BringzzText, BringzzButton } from "@bringzz/components";
import RocketImage from 'assets/images/Rocket.png'

export const collectSegments = [
    {
        status: "Matcing pharmacy...",
        subStatus: 'Pickup within XX:XX',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Waiting for pharmacy to accept request...</BringzzText>
        </div>
    },
    {
        status: "Order is being prepared...",
        subStatus: 'Pickup within XX:XX',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Pharmacy matched!</BringzzText>
            <BringzzText tag='h5' className={"font-normal"}>Your order is now being prepared...</BringzzText>
        </div>
    },
    {
        status: "Ready for collection!",
        subStatus: 'Pickup within XX:XX',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Your order is ready for pickup!</BringzzText>
            <BringzzText tag='h5' className={"font-normal"}>Scan the QR-Code on your order bag to confirm that you have received your order.</BringzzText>
            {/* <BringzzButton onClick={() => navigate('/camera/collect/collect')} className='bg-magic-lilac mt-5'>Confirm collection</BringzzButton> */}
        </div>
    },
]

export const pickupSegments = [
    {
        status: "Matcing pharmacy...",
        subStatus: 'Delivery arriving at XX:XX',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Waiting for pharmacy to accept request!</BringzzText>
        </div>
    },
    {
        status: "Matching shopper...",
        hidden: true,
        subStatus: 'Delivery arriving at XX:XX',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Pharmacy matched!</BringzzText>
            <BringzzText tag='h5' className={"font-normal"}>Now waiting for shopper to accept request...</BringzzText>
        </div>
    },
    {
        status: "Shopper is on his way...",
        subStatus: 'Delivery arriving at XX:XX',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Shopper matched!</BringzzText>
            <BringzzText tag='h5' className={"font-normal"}>He is on his way to pick up your receipt from you...</BringzzText>
        </div>
    },
    {
        status: "Shopper is on his way...",
        hidden: true,
        subStatus: 'Delivery arriving at XX:XX',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Shopper matched!</BringzzText>
            <BringzzText tag='h5' className={"font-normal"}>He is on his way to pick up your receipt from you...</BringzzText>
        </div>
    },
    {
        status: "Handover prescription...",
        subStatus: 'Delivery arriving at XX:XX',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Time to handover your prescription!</BringzzText>
            <BringzzText tag='h5' className={"font-normal"}>{"Scan the QR-Code on your shopper's phone and handover the prescription."}</BringzzText>
            {/* <BringzzButton onClick={() => navigate('/camera/pickup/handover')} className='bg-magic-lilac mt-5'>Handover prescription</BringzzButton> */}
        </div>
    },
    {
        status: "Order is being prepared...",
        hidden: true,
        subStatus: 'Delivery arriving at XX:XX',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Handover complete!</BringzzText>
            <BringzzText tag='h5' className={"font-normal"}>Your shopper is now on his way to pick up your order.</BringzzText>
        </div>
    },
    {
        status: "Order picked up...",
        subStatus: 'Delivery arriving at XX:XX',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Your order has been picker up!</BringzzText>
            <BringzzText tag='h5' className={"font-normal"}>Shopper is now on his way back to deliver your order..</BringzzText>
        </div>
    },
    {
        status: "Shopper has arrived...",
        subStatus: 'Delivered at XX:XX',
        content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>Shopper has arrived with your order!</BringzzText>
            <BringzzText tag='h5' className={"font-normal"}>Scan the QR-Code on your order bag to confirm that you have received your order.</BringzzText>
            {/* <BringzzButton className='bg-magic-lilac mt-5' onClick={() => navigate('/camera/pickup/confirm')}>Confirm delivery</BringzzButton> */}
        </div>
    },
]