import React, { useState } from 'react';
import {
	BringzzIcon,
	BringzzText,
	BringzzButton,
	BringzzAccordion,
	BringzzBottomDrawer,
	BringzzRotatingSelect
} from '@bringzz/components';
import { useOverlay } from 'context/OverlayContext';
import SubmittingOrderOverlay from 'components/SubmittingOrderOverlay';

const CheckoutSummary = ({ close, className, deliveryType = 1, ...props }) => {
	const [currentHours, setCurrentHours] = useState(null);
	const [currentMinutes, setCurrentMinutes] = useState(null);
	const [selectingTime, setSelectingTime] = useState(false);
	const [scheduledTime, setScheduledTime] = useState(null);
	const [selectedTip, setSelectedTip] = useState(null); // State for selected tip
	const [editPaymentMethod, setEditPaymentMethod] = useState(false);
	const { showOverlayContent, hideOverlay } = useOverlay();

	const handleShowOverlay = () => {
		showOverlayContent(
			<SubmittingOrderOverlay deliveryType={deliveryType} />
		);
	};

	const hoursOptions = [
		{ label: '1', value: 1 },
		{ label: '2', value: 2 },
		{ label: '3', value: 3 },
		{ label: '4', value: 4 },
		{ label: '5', value: 5 },
		{ label: '6', value: 6 },
		{ label: '7', value: 7 },
		{ label: '8', value: 8 },
		{ label: '9', value: 9 },
		{ label: '10', value: 10 },
		{ label: '11', value: 11 },
		{ label: '12', value: 12 }
	];

	const generateMinutesOptions = () => {
		let array = [];
		for (let index = 0; index < 60; index++) {
			array.push({ value: index, label: index.toString().padStart(2, '0') });
		}
		return array;
	};
	const minutesOptions = generateMinutesOptions();
	const closeTimeSelection = () => {
		const time = `${currentHours}:${currentMinutes.toString().padStart(2, '0')}`;
		setScheduledTime(time);
		setSelectingTime(false);
	};
	const changeHours = result => {
		setCurrentHours(result.value);
	};
	const changeMinutes = result => {
		setCurrentMinutes(result.value);
	};
	const selectTip = amount => {
		setSelectedTip(amount); // Update the selected tip
	};

	return (
		<div className=''>
			{!selectingTime ? (
				<div className='px-4 pb-4'>
					<div className='flex items-center justify-between py-4 px-2 cursor-pointer'>
						<div>
							<BringzzIcon
								size='24'
								icon='IconPharmacy'
								folder={'HealthIcons'}
							/>
						</div>
						<div className='flex flex-col -space-y-1 pr-8'>
							<BringzzText tag='h3'>Pharmacy</BringzzText>
							<div className='w-64'>
								<BringzzText
									tag='h3'
									className='text-normal text-black/50 whitespace-pre-line truncate'
								>
									Aphotheke Mustermann
								</BringzzText>
							</div>
							<BringzzText tag='h4' className='font-normal'>{deliveryType == 1 ? "Bringzz Pickup" : "Click & Collect"}</BringzzText>
						</div>
						<BringzzIcon size='18' icon={'IconPencil'} folder={'LineIcons'} />
					</div>
					{deliveryType === 1 && <div className='flex items-center justify-between py-4 px-2 cursor-pointer'>
						<BringzzIcon size='18' icon='IconMapMarker' folder={'LineIcons'} />
						<div className='flex flex-col pr-8'>
							<BringzzText tag='h3'>Deliver to</BringzzText>
							<div className='w-64'>
								<BringzzText
									tag='h3'
									className='text-normal text-black/50 whitespace-pre-line truncate'
								>
									Aphotheke Mustermann
								</BringzzText>
							</div>
						</div>
						<BringzzIcon size='18' icon={'IconPencil'} folder={'LineIcons'} />
					</div>}
					<div className='flex items-center justify-between py-4 px-2 cursor-pointer'>
						<BringzzIcon size='18' icon='IconCreditCard' folder={'LineIcons'} />
						<div className='flex flex-col pr-8'>
							<BringzzText tag='h3'>Payment Method</BringzzText>
							<div className='w-64'>
								<BringzzText
									tag='h3'
									className='text-normal text-black/50 whitespace-pre-line truncate'
								>
									Mastercard ***0904
								</BringzzText>
							</div>
						</div>
						<BringzzIcon size='18' icon={'IconPencil'} folder={'LineIcons'} />
					</div>
					{deliveryType == 1 && <div className='flex items-center space-x-4 justify-center bg-gray-200 py-2 px-2 cursor-pointer'>
						<BringzzText tag='h3'>Add Tip</BringzzText>
						<div className='w-64'>
							<div>
								<div className='flex items-center gap-x-3'>
									<BringzzButton
										className={`text-olive-green rounded-lg !px-3 flex justify-center font-semibold ${selectedTip === 1 ? 'bg-magic-lilac' : 'bg-sand '}`}
										onClick={() => selectTip(1)}
									>
										€1
									</BringzzButton>
									<BringzzButton
										className={`text-olive-green rounded-lg !px-3 flex justify-center font-semibold ${selectedTip === 3 ? 'bg-magic-lilac ' : 'bg-sand '}`}
										onClick={() => selectTip(3)}
									>
										€3
									</BringzzButton>
									<BringzzButton
										className={`text-olive-green rounded-lg !px-3 flex justify-center font-semibold ${selectedTip === 5 ? 'bg-magic-lilac' : 'bg-sand '}`}
										onClick={() => selectTip(5)}
									>
										€5
									</BringzzButton>
									<BringzzButton
										className={`text-olive-green rounded-lg !px-3 flex justify-center font-semibold ${selectedTip === 'Other' ? 'bg-magic-lilac' : 'bg-sand '}`}
										onClick={e => {
											e.stopPropagation();
											selectTip('Other');
										}}
									>
										Other
									</BringzzButton>
								</div>
							</div>
						</div>
					</div>}
					<div className='flex items-center justify-between py-4 px-2 cursor-pointer'>
						<BringzzIcon size='22' icon='IconTicketAlt' folder={'LineIcons'} />
						<div className='flex flex-col pr-8'>
							<BringzzText tag='h3'>Order Summary</BringzzText>
							<div className='w-64'>
								<BringzzText
									tag='h3'
									className='text-normal text-black/50 whitespace-pre-line truncate'
								>
									Total: € 18,00
								</BringzzText>
							</div>
						</div>
						<BringzzIcon icon='IconChevronDown' folder='LineIcons' size='18' />{' '}
					</div>

					<div className='my-6 px-4'>
						<BringzzText tag='h5' className='font-regular text-center'>
							By placing this order, I agree to the Terms of Use acknowledge
							that you have read Bringzz’s Privacy Policy.
						</BringzzText>
					</div>
					<div className='w-full flex justify-center gap-x-4'>
						<BringzzButton
							className='bg-magic-lilac rounded-full flex justify-center font-semibold'
							onClick={() => {
								close()
								handleShowOverlay()
							}}
						>
							Place order (€ 18,00) {scheduledTime ? `um ${scheduledTime}` : ''}
						</BringzzButton>
						<BringzzIcon
							folder='LineIcons'
							className='bg-lemon-yellow text-midnight-blue p-2 rounded-full'
							size='20'
							icon='IconClockAlt1'
							onClick={() => setSelectingTime(true)}
						/>
					</div>
				</div>
			) : (
				<>
					<div className='flex items-center relative gap-x-4 justify-center'>
						<div className='h-12 absolute top-1/2 right-0 w-full border-y -translate-y-1/2'></div>
						<div>
							<BringzzRotatingSelect
								options={hoursOptions}
								onSelect={changeHours}
							/>
						</div>
						<div>
							<BringzzRotatingSelect
								options={minutesOptions}
								onSelect={changeMinutes}
							/>
						</div>
					</div>

					<div className='flex justify-center items-center py-6'>
						<BringzzButton
							size='lg'
							className='bg-magic-lilac '
							onClick={closeTimeSelection}
						>
							Apply
						</BringzzButton>
					</div>
				</>
			)}

		</div>
	);
};

export default CheckoutSummary;
