// OrderContext.js
import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { useSocket } from './SocketContext';
import { collectSegments, pickupSegments } from 'constants/orderSegments';
import OrderRequest from 'components/OrderRequest';
import ProgressOverlay from 'components/ProgressOverlay'; // Import your ProgressOverlay component

// Create the OrderContext
const OrderContext = createContext();

// Create a provider component
export const OrderProvider = ({ children }) => {
    // State variables...
    const [currentStep, setCurrentStep] = useState(0);
    const [orderDetails, setOrderDetails] = useState(null);
    const [orderType, setOrderType] = useState('collect'); // Default to "collect"
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [overlayContent, setOverlayContent] = useState(null);
    const [activeStep, setActiveStep] = useState(0);

    const { sendMessage, onRefetchData } = useSocket();

    // Callback functions...
    const showOverlayContent = useCallback((content) => {
        setOverlayContent(content);
        setIsOverlayVisible(true);
    }, []);

    const hideOverlayContent = useCallback(() => {
        setOverlayContent(null);
        setIsOverlayVisible(false);
    }, []);

    const showOverlay = useCallback((initialStep = 0) => {
        setActiveStep(initialStep);
        setIsOverlayVisible(true);
    }, []);

    const hideOverlay = useCallback(() => {
        setIsOverlayVisible(false);
        setOverlayContent(null);
    }, []);

    const nextStep = useCallback(() => {
        setActiveStep((prevStep) => prevStep + 1);
    }, []);

    const resetSteps = useCallback(() => {
        setActiveStep(0);
    }, []);

    const startMatchingProcess = useCallback(
        (order, type = 'collect') => {
            setOrderDetails(order);
            setOrderType(type);
            // Hide the overlay if it's not needed
            setIsOverlayVisible(false);
        },
        []
    );

    const proceedToNextStep = useCallback(() => {
        nextStep();
        setCurrentStep((prevStep) => prevStep + 1);
    }, [nextStep]);

    const stopMatchingProcess = useCallback(() => {
        resetSteps();
        hideOverlay();
        setCurrentStep(0);
        setOrderDetails(null);
        setOrderType('collect');
    }, [resetSteps, hideOverlay]);

    const getCurrentSegment = useCallback(() => {
        const segments = orderType === 'pickup' ? pickupSegments : collectSegments;
        return segments[currentStep];
    }, [currentStep, orderType]);

    const getCurrentPage = useCallback((currentStep = 0) => {
        if (currentStep === 0) return '/matchmaking/pickup/1';
        // Add logic for other steps if needed
    }, []);

    useEffect(() => {
        const cleanupRefetchData = onRefetchData((data) => {
            if (data) {
                console.log({ data });
                if (data.notificationType.includes('ORDER_PROGRESS_')) {
                    setActiveStep(Number(data.notificationType.split('_')[2]));
                }
                if (data.notificationType.includes('ORDER_REQUEST')) {
                    showOverlayContent(<OrderRequest />);
                }
            }
            return;
        });

        return () => {
            if (cleanupRefetchData) {
                cleanupRefetchData();
            }
        };
    }, [onRefetchData, showOverlayContent]);

    // Function to get overlay content based on the current state
    const getOverlayContent = () => {
        if (overlayContent) {
            return overlayContent;
        }
        // Do not return ProgressOverlay here
        return null;
    };

    // Provide state and functions to all components in the tree
    return (
        <OrderContext.Provider
            value={{
                orderDetails,
                currentStep,
                orderType,
                isOverlayVisible,
                activeStep,
                showOverlay,
                hideOverlay,
                proceedToNextStep,
                resetSteps,
                startMatchingProcess,
                stopMatchingProcess,
                getCurrentSegment,
                getCurrentPage,
                showOverlayContent,
                hideOverlayContent,
            }}
        >
            {children}
            {isOverlayVisible && overlayContent && (
                <div className="fixed inset-0 bg-black/85 flex items-center justify-center z-50">
                    <div className="relative w-full h-full flex items-center justify-center">
                        {overlayContent}
                    </div>
                </div>
            )}
        </OrderContext.Provider>
    );
};

// Custom hook to use the OrderContext
export const useOrder = () => {
    const context = useContext(OrderContext);
    if (!context) {
        throw new Error('useOrder must be used within an OrderProvider');
    }
    return context;
};
