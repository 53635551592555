import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { NavigationProvider } from './context/NavigationContext';
import { LanguageProvider as AppLanguageProvider } from './context/LanguageContext';
import { OverlayProvider } from 'context/OverlayContext';
import '@bringzz/components/dist/index.css';
import './i18n';
import { AuthProvider } from 'context/AuthContext';
import { SocketProvider } from 'context/SocketContext';
import { ProgressOverlayProvider } from 'context/ProgressOverlayContext';

import { NotificationProvider } from 'context/NotificationContext';
import { OrderProvider } from 'context/OrderContext';

async function enableMocking() {
	if (process.env.REACT_APP_USE_BACKEND === 'false') {
		const { worker } = await import('./mocks/browser');
		return worker.start({
			onUnhandledRequest: 'bypass'
		});
	}
}

const root = ReactDOM.createRoot(document.getElementById('root'));

enableMocking().then(() => {
	root.render(
		// <React.StrictMode>
		<BrowserRouter>
			<AuthProvider>
				<SocketProvider>
					<NavigationProvider>
						{/* <ProgressOverlayProvider> */}
						<OrderProvider>
							<OverlayProvider>
								<NotificationProvider>
									<AppLanguageProvider>
										<App />
									</AppLanguageProvider>
								</NotificationProvider>
							</OverlayProvider>
						</OrderProvider>
						{/* </ProgressOverlayProvider> */}
					</NavigationProvider>
				</SocketProvider>
			</AuthProvider>
		</BrowserRouter>
		// </React.StrictMode>
	);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
