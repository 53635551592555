import React, { useState } from 'react';
import { BringzzAccordion, BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzBasketProduct
} from "@bringzz/components";

const OrderViewPage = () => {
    const { backPress } = useNavigation();

    const [products, setProducts] = useState([
        {
            name: 'Product Name 1',
            manufacturer: 'Manufacturer',
            amount: '84 Stk | Kapseln',
            quantity: '10',
            imgUrl: '/product.png',
            price: 9,
            inhalt: ""
        },
        {
            name: 'Product Name 2',
            manufacturer: 'Manufacturer 2',
            amount: '12 Stk | Kapseln',
            quantity: '3',
            imgUrl: '/product.png',
            price: 8,
            inhalt: "Prescription attached"
        }
    ]);

    return (
        <BringzzPageContainer
            className="bg-white"
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconChevronLeft'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                ),
                title: "Order #1234"
            }}
            authenticated={true}
        >

            <div className='px-6'>
                <div className='text-center my-4'>
                    <BringzzText tag='h1'>$XX.00</BringzzText>
                    <BringzzText tag='h3'>X items</BringzzText>
                </div>
                <div className='flex space-x-4 border-t border-b py-2'>
                    <div>
                        <BringzzIcon
                            folder='CustomIcons'
                            icon='IconMountain'
                            size='22'
                            className='bg-slate-100 p-3 rounded-full' />
                    </div>
                    <div className='flex w-full justify-between'>
                        <div>
                            <BringzzText tag='h3'>Customer</BringzzText>
                            <BringzzText tag='h4' className={"font-normal"}>Amine B.</BringzzText>
                        </div>
                        <div className='flex items-center space-x-2'>
                            <BringzzIcon
                                folder='LineIcons'
                                icon='IconPhone'
                                size='22' />
                            <BringzzIcon
                                folder='LineIcons'
                                icon='IconPopup'
                                size='22' />
                        </div>
                    </div>
                </div>
                <div className='flex space-x-4 border-b py-2'>
                    <div>
                        <BringzzIcon
                            folder='LineIcons'
                            icon='IconBox9'
                            size='22'
                            className='p-3 rounded-full' />
                    </div>
                    <div className='flex w-full'>
                        <div>
                            <BringzzText tag='h3'>Order Type</BringzzText>
                            <BringzzText tag='h4' className={"font-normal"}>Bringzz Shopper</BringzzText>
                        </div>
                    </div>
                </div>
                <div className='flex space-x-4 border-b py-2'>
                    <div>
                        <BringzzIcon
                            folder='LineIcons'
                            icon='IconFileName'
                            size='22'
                            className='px-3 py-2' />
                    </div>
                    <div className='flex w-full justify-between'>
                        <div className='flex items-center'>
                            <BringzzText tag='h3'>Prescription</BringzzText>
                        </div>
                        <div className='flex items-center space-x-2'>
                            <BringzzIcon
                                folder='LineIcons'
                                icon='IconEye'
                                size='22' />
                            <BringzzIcon
                                folder='LineIcons'
                                icon='IconBarcode'
                                size='22' />
                        </div>
                    </div>
                </div>
                <BringzzAccordion
                    title={<div className='flex space-x-4'>
                        <div>
                            <BringzzIcon
                                folder='LineIcons'
                                icon='IconCheckBoxAlt'
                                size='22'
                                className='p-3 rounded-full' />
                        </div>
                        <div>
                            <BringzzText tag='h3'>Order Total</BringzzText>
                            <BringzzText tag='h4' className={"font-normal"}>€XX.XX | X Items</BringzzText></div>
                    </div>}
                >
                    {products.map((product, index) => {
                        return (
                            <BringzzBasketProduct
                                key={index + 'product'}
                                product={product}
                                mode={"view"}
                            />
                        );
                    })}
                    <div className='py-3'>
                        <div className='flex justify-between'>
                            <BringzzText tag='h4' className="font-normal">Subtotal</BringzzText>
                            <BringzzText tag='h4' className="font-normal">€XX.XX</BringzzText>
                        </div>
                        <div className='flex justify-between'>
                            <BringzzText tag='h4' className="font-normal">Delivery fee & handling</BringzzText>
                            <BringzzText tag='h4' className="font-normal">€XX.XX</BringzzText>
                        </div>
                        <div className='flex justify-between'>
                            <div className='flex space-x-1 items-center'>
                                <BringzzText tag='h4'>Total</BringzzText>
                                <BringzzIcon
                                    icon="IconQuestionCircle"
                                    folder="LineIcons"
                                    className="h-4 w-4 cursor-pointer text-magic-lilac"
                                    size="16"
                                ></BringzzIcon>
                            </div>
                            <BringzzText tag='h4'>€XX.XX</BringzzText>
                        </div>
                    </div>
                </BringzzAccordion>

                <div className='flex space-x-4 border-b py-2'>
                    <div>
                        <BringzzIcon
                            folder='LineIcons'
                            icon='IconInvoice1'
                            size='22'
                            className='p-3 rounded-full' />
                    </div>
                    <div className='flex w-full justify-between'>
                        <div>
                            <BringzzText tag='h3'>Invoice</BringzzText>
                            <BringzzText tag='h4' className={"font-normal"}>Invoice #2023-123456</BringzzText>
                        </div>
                        <div className='flex items-center space-x-2'>
                            <BringzzButton size='sm' className="bg-magic-lilac">View</BringzzButton>
                        </div>
                    </div>
                </div>
                <div className='flex space-x-4 border-b py-2'>
                    <div>
                        <BringzzIcon
                            folder='LineIcons'
                            icon='IconBox9'
                            size='22'
                            className='p-3 rounded-full' />
                    </div>
                    <div className='flex w-full'>
                        <div>
                            <BringzzText tag='h3'>Processed by</BringzzText>
                            <BringzzText tag='h4' className={"font-normal"}>Employee name</BringzzText>
                            <BringzzText tag='h5' className={"font-normal"}>Bag 1 (XXXXXXXXX) | DD/MM/YY, HH:MM</BringzzText>
                        </div>
                    </div>
                </div>
                <div className='flex space-x-4 border-b py-2'>
                    <div>
                        <BringzzIcon
                            folder='LineIcons'
                            icon='IconBox9'
                            size='22'
                            className='p-3 rounded-full' />
                    </div>
                    <div className='flex w-full'>
                        <div>
                            <BringzzText tag='h3'>Handover by</BringzzText>
                            <BringzzText tag='h4' className={"font-normal"}>Employee name</BringzzText>
                            <BringzzText tag='h5' className={"font-normal"}>Bag 1 (XXXXXXXXX) | DD/MM/YY, HH:MM</BringzzText>
                        </div>
                    </div>
                </div>
                <div className='flex space-x-4 border-b py-2'>
                    <BringzzButton size='sm' className="bg-magic-lilac">Problem with payment?</BringzzButton>
                </div>
            </div>
        </BringzzPageContainer >
    );
};

export default OrderViewPage;
