import React, { useEffect, useState, useRef } from 'react';
import {
    BringzzButton,
    BringzzIcon,
    BringzzText,
} from '@bringzz/components';
import { useOverlay } from 'context/OverlayContext';
import { useNavigation } from 'context/NavigationContext';
import useRequest from 'hooks/useRequest';
import { useOrder } from 'context/OrderContext';

const SubmittingOrderOverlay = ({ deliveryType = 1 }) => {
    const { navigate } = useNavigation();
    const { sendRequest } = useRequest();
    const { hideOverlay } = useOverlay();
    const { startMatchingProcess } = useOrder()
    const [response, setResponse] = useState(null);
    const responseRef = useRef(null); // <-- Use useRef to store the latest response
    const orderData = {
        "products": [
            {
                "id": 3,
                "quantity": 3
            }
        ],
        "pharmacyId": 0,
        "deliveryAddress": {
            "name": "Amine Bouzazi",
            "streetName": "Am Teich",
            "postalCode": 12345,
            "cityName": "Marburg",
            "latitude": "1.0",
            "longitude": "2.0",
            "note": "some note"
        },
        "billAddress": {
            "name": "Amine Bouzazi",
            "streetName": "Am Teich",
            "postalCode": 12345,
            "cityName": "Marburg"
        },
        "message": "Some message",
        "payment": -1,
        "price": "12.0"
    }

    const submitOrder = async () => {
        try {
            const response = await sendRequest({
                method: 'POST',
                url: `/module/order/createOrder`,
                data: orderData
            });

            if (response.status === "error") {
                hideOverlay();
            } else {
                setResponse(response);
                responseRef.current = response; // <-- Update the ref with the latest response
            }
        } catch (error) {
            hideOverlay();
            console.error(error);
        }
    };

    useEffect(() => {
        submitOrder();

        const goTo = `/matchmaking/${deliveryType == 1 ? "pickup" : "collect"}/1`;
        const timer = setTimeout(() => {
            console.log("hneee");
            console.log(responseRef.current); // <-- Use the ref to access the latest response

            if (responseRef.current) {
                hideOverlay();
                startMatchingProcess({ ...orderData, id: responseRef.current.data.order })
                navigate(goTo);
            } else {
                hideOverlay();
            }
        }, 5000);

        return () => clearTimeout(timer);
    }, []);

    return <div className="h-full w-full text-white">
        <div className='p-8'>
            <div className='mb-2 flex items-center justify-between'>
                <BringzzText tag='h1' className='text-white'>Submitting order...</BringzzText>
                <div style={{ animation: 'spin 4s linear infinite' }}>
                    <svg width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 2V6M12 18V22M6 12H2M22 12H18M19.0784 19.0784L16.25 16.25M19.0784 4.99994L16.25 7.82837M4.92157 19.0784L7.75 16.25M4.92157 4.99994L7.75 7.82837" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
            {deliveryType == 1 && <div className='flex items-center justify-between py-2 px-2'>
                <BringzzIcon size='26' icon='IconMapMarker' folder={'LineIcons'} />
                <div className='flex flex-col pr-8'>
                    <BringzzText tag='h3' className='text-white'>Deliver to</BringzzText>
                    <div className='w-64'>
                        <BringzzText
                            tag='h3'
                            className='text-normal text-white whitespace-pre-line truncate'
                        >
                            Musterstrasse 10, 1040 Wien
                        </BringzzText>
                    </div>
                </div>
            </div>}
            <div className='flex items-center justify-between py-2 px-2'>
                <BringzzIcon size='22' icon='IconPackage' folder={'LineIcons'} />
                <div className='flex flex-col pr-8'>
                    <BringzzText tag='h3' className='text-white'>Delivery type</BringzzText>
                    <div className='w-64'>
                        <BringzzText
                            tag='h3'
                            className='text-normal text-white whitespace-pre-line truncate'
                        >
                            {deliveryType == 1 ? "Bringzz Pickup" : "Click & Collect"}
                        </BringzzText>
                    </div>
                </div>
            </div>
            <div className='flex items-center justify-between py-2 px-2'>
                <div>
                    <BringzzIcon
                        size='28'
                        icon='IconPharmacy'
                        folder={'HealthIcons'}
                    />
                </div>
                <div className='flex flex-col pr-8'>
                    <BringzzText tag='h3' className='text-white'>Pharmacy</BringzzText>
                    <div className='w-64'>
                        <BringzzText
                            tag='h3'
                            className='text-normal text-white whitespace-pre-line truncate'
                        >
                            Aphotheke Mustermann
                        </BringzzText>
                    </div>
                </div>
            </div>
            <div className='my-2 text-white'><hr></hr></div>
            <div className='flex py-2 px-2 '>
                <div className='mr-8'>
                    <BringzzIcon
                        size='24'
                        icon='IconTicketAlt'
                        folder={'LineIcons'}
                    />
                </div>
                <div className='flex flex-col w-full'>
                    <BringzzText tag='h3' className='text-white'>Order Summary</BringzzText>
                    <div className='w-full mt-1'>
                        <div className='flex justify-between w-full'>
                            <BringzzText
                                tag='h4'
                                className='font-normal text-white whitespace-pre-line truncate'
                            >
                                Subtotal
                            </BringzzText>
                            <BringzzText
                                tag='h4'
                                className='font-normal text-white whitespace-pre-line truncate'
                            >
                                € 18,00
                            </BringzzText>
                        </div>
                        <div className='flex justify-between w-full'>
                            <div className='flex items-center space-x-1'>
                                <BringzzText
                                    tag='h4'
                                    className='font-normal text-white whitespace-pre-line truncate'
                                >
                                    Delivery fee & handling
                                </BringzzText>
                                <BringzzIcon
                                    icon="IconQuestionCircle"
                                    folder="LineIcons"
                                    className="h-4 w-4 cursor-pointer text-white"
                                    size="18"
                                ></BringzzIcon>
                            </div>
                            <BringzzText
                                tag='h4'
                                className='font-normal text-white whitespace-pre-line truncate'
                            >
                                € XX,00
                            </BringzzText>
                        </div>
                        <div className='flex justify-between w-full'>
                            <BringzzText
                                tag='h4'
                                className='font-bold text-white whitespace-pre-line truncate'
                            >
                                Total
                            </BringzzText>
                            <BringzzText
                                tag='h4'
                                className='font-bold text-white whitespace-pre-line truncate'
                            >
                                € 18,00
                            </BringzzText>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='absolute bottom-0 w-full'>
            <div className='flex justify-center'>
                <BringzzButton size='lg' className="text-black bg-white" onClick={hideOverlay}>Cancel order</BringzzButton>
            </div>
        </div>
    </div>
}

export default SubmittingOrderOverlay;