import React, { useEffect, useState } from 'react';
import {
	BringzzPageContainer,
	BringzzIcon,
	BringzzText,
	BringzzImage,
	BringzzPostingCard,
	BringzzButton,
	BringzzModal,
	BringzzArticle
} from '@bringzz/components';
import { useNavigation } from 'context/NavigationContext';
import { useLocation } from 'react-router-dom';
import useRequest from 'hooks/useRequest';
import AvatarExample from 'assets/images/AvatarExample.png';
import PostExample1 from 'assets/images/PostExample1.png';
import { useAuth } from 'context/AuthContext';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const PostDetail = () => {
	const { backPress } = useNavigation();
	let query = useQuery();
	const { sendRequest, loading } = useRequest();

	const { navigate } = useNavigation();
	let id = query.get('id');
	const [post, setPost] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);
	const { user } = useAuth();

	const fetchPost = async () => {
		await sendRequest({
			method: 'GET',
			url: `/module/post/getPostById?id=${id}`,
		}).then((response) => {
			if (response.status === 'success') {
				console.log(response.data);
				setPost(response.data)
			}
		}).catch((error) => {
			console.error(error);
		})
	}

	useEffect(() => {
		if (id) {
			fetchPost()
		}
	}, [id])

	const handleEditClick = () => {
		if (post.type == 2) {
			navigate(`/posts/edit/external-link`, {
				state: {
					id: post.id,
					ogImage: post.images[0].content,
					headline: post.headline,
					description: post.description,
					externalLink: post.link
				}
			})
		}
	}

	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				),

				title: <BringzzText tag='h2'>{post && (post.published ? "Published" : "Unpublished")} Post</BringzzText>
			}}
			footer={
				<div className='pb-28 px-6 flex flex-row space-x-4'>
					<BringzzButton
						onClick={() => setModalOpen(true)}
						className="border w-full border-black justify-center"
						disabled={loading}
					>
						Delete
					</BringzzButton>
					<BringzzButton
						onClick={handleEditClick}
						className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
						disabled={loading}
					>
						Edit
					</BringzzButton>
				</div>
			}
		>
			<div className='p-4 space-y-4'>
				{post && post.type == 1 &&
					<div className=''>
						<BringzzArticle
							title={post.headline || ''}
							subTitle={post.description || ''}
							likesCount={100}
							commentsCount={10}
							interactionBar={false}
						/>

						<div className="flex items-center space-x-2 px-4">
							<img src={AvatarExample} className="h-8 w-8" alt="avatar" />
							<BringzzText
								tag="h4"
								className={"font-bold"}
							>
								{user.fullName}
							</BringzzText>
							<div className='flex items-center space-x-1'>
								<span className="text-[5px]">&#9679;</span>
								<BringzzText
									tag="h4"
									className={'font-normal text-magic-lilac'}
								>
									Follow
								</BringzzText>
							</div>
						</div>

						<div className='mt-3'>
							<BringzzImage src={post.images[0].content} alt='article image' className='h-56 w-full' />
							<BringzzText className={'font-normal italic p-3'} tag='h5'>{post.imageCaption}</BringzzText>
						</div>
						{post.components && post.components.map((block, index) => {
							return <div key={index} className='px-3'>
								{block.component == "body_copy" && <BringzzText tag='h4'>{block.content}</BringzzText>}
								{block.component == "inline_heading" && <BringzzText tag='h2'>{block.content}</BringzzText>}
							</div>
						})}
					</div>
				}
				{post && post.type == 2 &&
					<BringzzPostingCard
						avatar={AvatarExample}
						name={user.fullName}
						isPreview={true}
						header={
							<BringzzImage
								className='w-full h-48'
								src={post.images[0].content}
								alt='post image'
							></BringzzImage>
						}
						title={post.headline}
						description={post.description}
						action={{
							label: 'Read more',
							icon: 'IconEbook',
							iconFolder: 'LineIcons',
						}}
					></BringzzPostingCard>
				}
				{post && post.type == 3 &&
					<BringzzPostingCard
						avatar={AvatarExample}
						name={user.fullName}
						sponsored={true}
						isPreview={true}
						header={
							<BringzzImage
								className='w-full h-auto'
								src={post.images[0].content}
								alt='post image'
							></BringzzImage>
						}
						title={post.headline || ''}
						description={post.description || ''}
						action={{
							label: 'View products',
							icon: 'IconEbook',
							iconFolder: 'LineIcons',
						}}
					></BringzzPostingCard>
				}
				{post &&
					<div className='flex space-x-2 p-3 border rounded-md items-center'>
						<div className='rounded-full border border-black h-4 w-4 flex justify-center items-center p-3'>
							<BringzzText tag='h3'>i</BringzzText>
						</div>
						<BringzzText tag='h5'>{
							post.published ?
								"This post has already been published and will be updated instantly with your changes."
								:
								post.release ?
									`This post is scheduled to be posted on ${post.release}`
									:
									"This post has not been scheduled and will be vallid as soon as you share it."
						}</BringzzText>
					</div>
				}
			</div>
			{modalOpen && (
				<BringzzModal
					title={'Delete this post?'}
					description={`Sed pellentesque porttitor ipsum non libero lacinia. Fermentum aliquam nibh in.`}
					onCancel={() => setModalOpen(false)}
					onConfirm={async () => {
						await sendRequest({
							method: 'POST',
							url: `/module/post/deletePost`,
							data: {
								id
							}
						})
							.then(response => {
								if (response.status === 'success')
									navigate(`/settings/posts`, false);
							})
							.catch(error => {
								console.log(error);
							});
					}}
					confirmButtonText='Delete'
				/>
			)}
		</BringzzPageContainer>
	);
};

export default PostDetail;
