// SearchOverviewPage.js
import React, { useState, useEffect } from 'react';
import { useNavigation } from 'context/NavigationContext';
import { BringzzPageContainer, BringzzInput, BringzzIcon, BringzzText } from "@bringzz/components";
import HeaderDefault from './HeaderDefault';
import Categories from './Categories';
import Groups from './Groups';
import ScanCTA from './ScanCTA';
import GeneralOptions from './GeneralOptions';
import SearchFooter from './SearchFooter';
import { categories, alphabets } from './constants';
import classNames from 'classnames';
import HeaderCategory from './HeaderCategory';
import HeaderInput from './HeaderInput';

const INITIAL_HEADER_HEIGHT = 214;
const SHRINK_SPEED = 1;

const SearchOverviewPage = () => {
    const { navigate } = useNavigation();
    const [searchQuery, setSearchQuery] = useState("");
    const [headerHeight, setHeaderHeight] = useState(INITIAL_HEADER_HEIGHT);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [MIN_HEADER_HEIGHT, SET_MIN_HEADER_HEIGHT] = useState(48)
    const [isHeaderOpen, setIsHeaderOpen] = useState(true);
    const [searchData, setSearchData] = useState(null)
    const [alphabetFilter, setAlphanetFilter] = useState('All')

    const handleScroll = () => {
        const scrollTop = window.scrollY;
        const scrollDifference = scrollTop - lastScrollTop;

        if (scrollDifference > 0) {
            const newHeight = Math.max(MIN_HEADER_HEIGHT, headerHeight - scrollDifference * SHRINK_SPEED);
            setHeaderHeight(newHeight);
        } else if (scrollTop < 170 && scrollDifference < 0) {
            const newHeight = Math.min(INITIAL_HEADER_HEIGHT, headerHeight - scrollDifference * SHRINK_SPEED);
            setHeaderHeight(newHeight);
        }

        setLastScrollTop(scrollTop);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [lastScrollTop, headerHeight]);

    const toggleHeaderHeight = () => {
        setIsHeaderOpen(prevState => !prevState);
        setHeaderHeight(prevHeight =>
            prevHeight === INITIAL_HEADER_HEIGHT ? MIN_HEADER_HEIGHT : INITIAL_HEADER_HEIGHT
        );
    };

    console.log(searchData);
    console.log(MIN_HEADER_HEIGHT);

    return (
        <BringzzPageContainer
            className="bg-sand pb-40"
            authenticated={false}
            footerHover={true}
            footer={
                <div className='p-4'>
                    <BringzzInput
                        className='shadow-xl border-slate-400 bg-gray-100'
                        placeholder='Search for Productnames'
                        onChange={(e, newIsValid, newValue) => setSearchQuery(newValue)}
                        icon={{
                            left: <BringzzIcon
                                size="20"
                                icon="IconSearchAlt"
                                folder="LineIcons"
                                className="cursor-pointer"
                                onClick={() => {
                                    if (searchQuery.length !== 0) {
                                        setSearchData({
                                            method: "input",
                                            value: searchQuery
                                        })
                                    } else {
                                        setSearchData(null)
                                    }
                                }}
                            />,
                        }}
                        onEnterPress={() => {
                            if (searchQuery.length !== 0) {
                                setSearchData({
                                    method: "input",
                                    value: searchQuery
                                })
                            } else {
                                setSearchData(null)
                            }
                        }}
                    />
                </div>
            }
        >
            <div className='relative'>
                {!searchData && <HeaderDefault headerHeight={headerHeight} />}
                {searchData && searchData.method == "category" && <HeaderCategory SET_MIN_HEADER_HEIGHT={SET_MIN_HEADER_HEIGHT} searchData={searchData} setSearchData={setSearchData} headerHeight={headerHeight} />}
                {searchData && searchData.method == "input" && <HeaderInput SET_MIN_HEADER_HEIGHT={SET_MIN_HEADER_HEIGHT} searchData={searchData} setSearchData={setSearchData} headerHeight={headerHeight} />}
                <div style={{ paddingTop: searchData && searchData.method == "input" ? `60px` : `${INITIAL_HEADER_HEIGHT}px` }}>
                    {!searchData && <div>
                        <Categories setSearchData={setSearchData} callBack={() => {
                            SET_MIN_HEADER_HEIGHT(100)
                            setHeaderHeight(190)
                        }} />
                        <Groups />
                        <ScanCTA />
                        <GeneralOptions />
                    </div>}
                    {searchData && <div className='rounded-xl bg-white mx-2 py-2'>
                        {searchData.method == "category" && <div>
                            <div className='flex items-center px-4 space-x-2 overflow-x-auto '>
                                <BringzzText tag='h3' className='whitespace-nowrap mr-1'>A-Z</BringzzText>
                                {alphabets.map((char, index) => {
                                    return <div key={index} className={classNames('bg-sand p-2 rounded-full cursor-pointer',
                                        char == alphabetFilter && "!bg-magic-lilac"
                                    )}
                                        onClick={() => setAlphanetFilter(char)}
                                    >
                                        <BringzzText tag='h5'>{char}</BringzzText>
                                    </div>
                                })}
                            </div>
                            {categories.filter((category) => category.shortName == searchData.value)[0].items
                                .filter((item) => {
                                    if (alphabetFilter == 'All') {
                                        return true
                                    } else if (alphabetFilter == '#') {
                                        return !/^[A-Za-z]/.test(item.label);
                                    } else {
                                        return item.label.startsWith(alphabetFilter)
                                    }
                                })
                                .map((item, index) => {
                                    return <div key={index} className={classNames(
                                        'h-12 cursor-pointer flex justify-between px-6 items-center',
                                    )}>
                                        <div className='flex space-x-1 items-center'>
                                            <BringzzText tag='h4'>{item.label.split("*")[0]}</BringzzText>
                                            <div>&bull;</div>
                                            <BringzzText tag='h4'>{item.label.split("*")[1]}</BringzzText>
                                        </div>
                                        <BringzzIcon
                                            size="20"
                                            icon="IconChevronRight"
                                            folder="LineIcons"
                                            className="cursor-pointer"
                                        />
                                    </div>
                                })}
                        </div>}
                        {searchData.method == "input" && <div>
                            {searchQuery.includes("bookmark") && <div className='px-4 py-2'>
                                <BringzzText tag='h2'>Suggestions</BringzzText>
                                <div className='flex justify-between cursor-pointer my-2' onClick={() => navigate('/settings/bookmarks')}>
                                    <BringzzText tag='h4' className={''}>Bookmarks</BringzzText>
                                    <BringzzIcon icon='IconChevronRight' folder='LineIcons' size='18' />
                                </div>
                            </div>}
                            {searchQuery.includes("order") && <div className='px-4 py-2'>
                                <BringzzText tag='h2'>Suggestions</BringzzText>
                                <div className='flex justify-between cursor-pointer my-2' onClick={() => navigate('/consumer/orders')}>
                                    <BringzzText tag='h4' className={''}>Orders</BringzzText>
                                    <BringzzIcon icon='IconChevronRight' folder='LineIcons' size='18' />
                                </div>
                            </div>}
                            {searchQuery.includes("advice") && <div className='px-4 py-2'>
                                <BringzzText tag='h2'>Suggestions</BringzzText>
                                <div className='flex justify-between cursor-pointer my-2'>
                                    <BringzzText tag='h4' className={''}>Chat with a Pharmacy</BringzzText>
                                    <BringzzIcon icon='IconChevronRight' folder='LineIcons' size='18' />
                                </div>
                                <div className='flex justify-between cursor-pointer my-2' >
                                    <BringzzText tag='h4' className={''}>Call a Pharmacy</BringzzText>
                                    <BringzzIcon icon='IconChevronRight' folder='LineIcons' size='18' />
                                </div>
                            </div>}
                            {searchQuery.includes("call") && <div className='px-4 py-2'>
                                <BringzzText tag='h2'>Suggestions</BringzzText>
                                <div className='flex justify-between cursor-pointer my-2'>
                                    <BringzzText tag='h4' className={''}>Call a Pharmacy</BringzzText>
                                    <BringzzIcon icon='IconChevronRight' folder='LineIcons' size='18' />
                                </div>
                            </div>}
                            {searchQuery.includes("pharmacy") && <div className='px-4 py-2'>
                                <BringzzText tag='h2'>Suggestions</BringzzText>
                                <div className='flex justify-between cursor-pointer my-2' onClick={() => navigate('/search/pharmacy')}>
                                    <div className='flex items-center space-x-2'>
                                        <BringzzIcon icon='IconPharmacy' folder='HealthIcons' size='28' />
                                        <BringzzText tag='h4' className={''}>Find a Pharmacy</BringzzText>
                                    </div>
                                    <BringzzIcon icon='IconChevronRight' folder='LineIcons' size='18' />
                                </div>
                            </div>}
                        </div>}
                    </div>}
                    <SearchFooter />
                </div>
            </div>
        </BringzzPageContainer>
    );
};

export default SearchOverviewPage;
